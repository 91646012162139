.courseUser {
  height: 100%;
}
.el-textarea {
  resize: none;
}
.el-textarea .el-textarea__inner {
  height: 200px;
}
.listWrap .el-icon-arrow-down {
  font-size: 12px;
}
.listWrap .el-icon-arrow-down:before {
  content: "\e6df" !important;
}
.listWrap .el-tabs {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.listWrap .el-tabs .el-tabs__content {
  flex: 1;
}
.btnBox .el-button.is-disabled,
.btnBox .el-button.is-disabled:focus,
.btnBox .el-button.is-disabled:hover {
  background: #a9a9a9;
  border-color: #a9a9a9;
  color: #fff;
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
.el-table th.is-left > .cell {
  text-align: left;
}
.el-table th.is-right > .cell {
  text-align: right;
}
.subject {
  padding-left: 15px;
}
.subject .subject-title {
  font-size: 1rem;
}
.listExam {
  margin: 15px 0;
  background: #fafafa;
  min-height: 7.5rem;
}
.listExam .list-item {
  padding-top: 0.5rem;
}
.rowJsontitle {
  padding: 20px;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-around;
}
.rowJsontitle > div {
  display: flex;
  align-items: center;
  font-size: 15px;
}
